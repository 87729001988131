<template>
  <div>
    <div class="nav" id="home">
      <img src="@/images/wap/logo_xiuye_hf@2x.png" />
      <img @click="show = !show" src="@/images/wap/top_more@2x.png" />
    </div>
    <div class="banner">
      <!-- <div class="mantle"></div> -->
      <!-- <video autoplay muted loop>
        <source src="https://prod-storage.moerlong.com/oss1457610892010061824.mp4" type="video/mp4" />
      </video> -->
      <img src="@/images/wap/banner_wa@2x.png" />
    </div>
    <van-popup
      v-model="show"
      position="top"
      :style="{ height: '56.5rem', width: '100%' }"
      closeable
    >
      <div class="show">
        <div class="list_box">
          <div
            class="list"
            v-for="(item, index) in menu"
            :key="item.id"
            @click="changeMenuIndexEvent(index, item.id)"
          >
            <img :src="item.url" /> <span> {{ item.title }}</span>
          </div>
        </div>
        <img class="xiuye" src="@/images/wap/logo_xiuye_hf_1@2x.png" />
      </div>
    </van-popup>

    <div class="advertisement" id="flow">
      <div class="advertisement_title">
        <div>打通业务各个环节<br />数据全方位共享</div>
        <img src="@/images/wap/title@2x.png" />
      </div>
      <div class="advertisement_list_box">
        <div
          class="advertisement_list"
          v-for="(item, index) in advertisements"
          :key="item.title"
        >
          <img :src="item.url" />
          <div class="advertisements_info">
            <div>{{ item.title }}</div>
            <div
              :style="{
                borderBottom:
                  advertisements.length - 1 != index ? '' : '0px solid #E9E9E9',
              }"
            >
              {{ item.info }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="process">
      <div class="process_title">
        <div>
          <span style="color: #ffc221">流程全自动化</span><br />
          提供公平透明的金融环境
        </div>
        <img src="@/images/wap/title@2x.png" />
      </div>
      <div class="process_list_box">
        <div
          class="process_list"
          v-for="(item, index) in environments"
          :key="item.title"
        >
          <div class="process_list_line">
            <img :src="item.url" />
            <div class="info">
              <div>{{ item.title }}</div>
              <div
                v-if="item.title === '客户调解回款'"
                style="
                  font-size: 1.375rem;
                  font-weight: normal;
                  margin-top: 0.875rem;
                "
              >
                (不回款线上开庭、执行)
              </div>
            </div>
          </div>
          <img
            class="pot"
            src="@/images/wap/jt@2x.png"
            v-if="environments.length - 1 != index"
          />
        </div>
      </div>
    </div>

    <div class="about" id="about">
      <div class="about_title">
        <div>关于我们</div>
        <div>一站式金融借贷纠纷服务平台</div>
      </div>
      <div class="about_content">
        主要针对金融痛点，利用互联网加密、光闸数据交换等技术，使数据实现安全交换共享从而达到人民法院的认可和支持，实现法院与系统平台的数据标准统一，为金融借贷纠纷提供的一站式、高效批量化处理的在线诉讼技术服务。为金融机构（律师事务所等）提供小金额借款合同纠纷案件纯线上批量申请立案、批量保全、调解、批量申请执行等10多项诉讼技术服务功能，将诉讼全流程服务从线下搬到线上“一键式处理”，案件材料处理采用全电子档案的方式，同时响应了人民法院全流程无纸化办案新模式，提高了企业对借贷纠纷案件的诉讼效率和诉讼数量，案件全流程自动化跟踪处理。
      </div>
      <div class="adout_environment">
        <div class="adout_environment_info">
          <img src="@/images/icon_about_sjgx@2x.png" />
          <div>数据安全交换共享</div>
        </div>
        <div class="adout_environment_info">
          <img src="@/images/icon_about_wzbg@2x.png" />
          <div>无纸化办案新模式</div>
        </div>
      </div>
      <div class="adout_environment_s">
        <div class="adout_environment_s_box">
          <img src="@/images/about_small_bj@2x.png" />
          <div>
            解决贷后痛点，完成失联修复<br />
            树立诚信意识，构建和谐社会
          </div>
        </div>
      </div>
    </div>

    <div class="founder" id="process">
      <particles class="particles" />
      <div class="process_title">
        <div>创始人介绍</div>
        <img src="@/images/wap/title@2x.png" />
      </div>
      <van-swipe
        @change="onChange"
        class="my-swipe"
        :autoplay="3000"
        :show-indicators="false"
      >
        <van-swipe-item
          class="swipe_item"
          v-for="item in processDatas"
          :key="item.name"
        >
          <div class="founder_info">
            <img class="dian" src="@/images/wap/dian@2x.png" />
            <div class="founder_info_user">
              <img :src="item.url" />
              <div class="founder_info_more">
                <div>{{ item.name }}</div>
                <div>{{ item.position }}</div>
              </div>
            </div>
            <div class="founder_text" v-html="item.info"></div>
          </div>
        </van-swipe-item>
      </van-swipe>
      <div class="current">
        <div
          :class="current == index ? 'utag' : 'tag'"
          v-for="(item, index) in processDatas"
          :key="index"
        ></div>
      </div>
    </div>

    <div class="reason">
      <div class="reason_title">
        <div>选择我们的六大理由</div>
        <img src="@/images/wap/title@2x.png" />
      </div>
      <div class="reason_box">
        <van-swipe
          class="reason_swipe"
          :autoplay="3000"
          :show-indicators="false"
          @change="reasonCurrentChange"
        >
          <van-swipe-item
            class="reason_box_item"
            v-for="item in reasons"
            :key="item.title"
          >
            <div class="reason_box_item_info">
              <img class="user_img" :src="item.url" />
              <div class="reason_info_user">
                <div>{{ item.title }}</div>
                <div>{{ item.info }}</div>
              </div>
            </div>
          </van-swipe-item>
        </van-swipe>
        <div class="reason_pot">
          <div
            :class="reasonCurrent == index ? 'a_pot' : 'pot'"
            v-for="(item, index) in reasons"
            :key="item.title"
          ></div>
        </div>
      </div>
    </div>

    <div class="contact" id="contact">
      <div class="contact_title">
        <div>招聘信息</div>
        <div>岗位：调解员（20-40人）</div>
      </div>
      <img src="@/images/wap/w@2x.png" class="bg_img" />
      <div class="contact_box">
        <div class="contact_box_info">
          <div>招聘内容</div>
          <div>
            1.工作地点：四川省雅安市<br />
            2.工作时间：朝九晚六、周末双休、享受国家法定节假日<br />
            3.薪资待遇：4-8k
          </div>
        </div>

        <div class="contact_box_info">
          <div>岗位职责</div>
          <div>
            1.根据公司要求负责逾期客户的电话调解<br />
            2.协助客户还款、解除财产冻结、撤诉等相关工作<br />
            3.做好数据统计与资料储存
          </div>
        </div>

        <div class="contact_box_info">
          <div>简历投递</div>
          <div>
            1.姓名：高冉<br />
            2.电话：13688034340<br />
            3.邮箱：gaoran@xiuyekeji.com
          </div>
        </div>
      </div>
    </div>

    <footer class="footer">
      <div class="footer_list_box">
        <div
          class="footer_list"
          v-for="item in footerContact"
          :key="item.title"
        >
          <div>{{ item.title }}</div>
          <div v-html="item.info"></div>
        </div>
      </div>
      <div class="cooperate">
        政府合作支持单位：四川蜀渝云数据产业园有限公司<br />
        法律支持单位：四川全盈律师事务所<br />
        旗下公司：雅安市君信基业商务信息咨询有限公司<br />
        &#12288;&#12288;&#12288;&#12288;四川品资商务信息咨询服务有限公司
      </div>
      <div class="corporation">
        Copyright © 2021 XIUYEKEJI Corporation.All Rights Reserved.
        四川雅安修业科技服务有限公司 版权所有<br />
        <a
          href="https://beian.miit.gov.cn/#/Integrated/index"
          target="_blank"
          style="color: #7e889d"
        >
         蜀ICP备2021028841号
        </a>
      </div>
    </footer>
  </div>
</template>
<script>
import particles from "@/components/particles/index.vue";
export default {
   metaInfo: {
    title: "修业科技",
    meta: [
      {
        // set meta
        name: "keyWords",
        content: "修业科技,修业科技官网,雅安修业科技有限公司",
      },
      {
        name: "description",
        content:
          "主要针对金融痛点，利用互联网加密、光闸数据交换等技术，使数据实现安全交换共享从而达到人民法院的认可和支持，实现法院与系统平台的数据标准统一，为金融借贷纠纷提供的一站式、高效批量化处理的在线诉讼技术服务。为金融机构（律师事务所等）提供小金额借款合同纠纷案件纯线上批量申请立案、批量保全、调解、批量申请执行等10多项诉讼技术服务功能，将诉讼全流程服务从线下搬到线上“一键式处理”，案件材料处理采用全电子档案的方式，同时响应了人民法院全流程无纸化办案新模式，提高了企业对借贷纠纷案件的诉讼效率和诉讼数量，案件全流程自动化跟踪处理。",
      },
    ],
    link:[{
      rel: 'asstes',
      href: 'https://www.xiuyekeji.com/wap'
    }]
  },
  data() {
    return {
      show: false,
      current: 1,
      reasonCurrent: 1,
      footerContact: [
        {
          title: "总经理：彭德伟",
          info: `电话：13548003846 <br> 邮箱：pengdewei@xiuyekeji.com`,
        },
        {
          title: "商务总监：吴天",
          info: "电话：13880348070 <br> 邮箱：3431877460@qq.com",
        },
        {
          title: "四川雅安修业科技服务有限公司",
          info: "四川省雅安市名山区蒙阳镇水井街88号11幢12层1206号",
        },
      ],
      menu: [
        {
          title: "首 页",
          id: "home",
          url: require("@/images/wap/nav_01@2x.png"),
        },
        {
          title: "业务流程",
          id: "flow",
          url: require("@/images/wap/nav_02@2x.png"),
        },
        {
          title: "关于我们",
          id: "about",
          url: require("@/images/wap/nav_03@2x.png"),
        },
        {
          title: "创始人团队",
          id: "process",
          url: require("@/images/wap/nav_04@2x.png"),
        },
        {
          title: "招聘信息",
          id: "contact",
          url: require("@/images/wap/nav_05@2x.png"),
        },
      ],
      advertisements: [
        {
          url: require("@/images/icon_01@2x.png"),
          title: "全流程反馈",
          info: "案件处理流程节点全程追踪，反馈自动化。",
        },
        {
          url: require("@/images/icon_02@2x.png"),
          title: "专业透明",
          info: "利用司法公开的特点，营造稳定、公平、透明、可预期的金融环境，提供有力的司法服务和保障。",
        },
        {
          url: require("@/images/icon_03gxbj@2x.png"),
          title: "高效便捷",
          info: "线上实现法院、律所、金融机构等数据标准统一，诉讼材料在线批量处理。",
        },
      ],
      environments: [
        {
          title: "诉讼材料批量生成",
          url: require("@/images/icon_business_01@2x.png"),
        },
        {
          title: "批量移送法院起诉",
          url: require("@/images/icon_business_02@2x.png"),
        },
        {
          title: "法院批量立案保全",
          url: require("@/images/icon_business_03@2x.png"),
        },
        {
          title: "客户调解回款",
          url: require("@/images/icon_business_04@2x.png"),
        },
        {
          title: "结案",
          url: require("@/images/icon_business_05@2x.png"),
        },
      ],
      processDatas: [
        {
          url: require("@/images/pic__photo_01@2x.png"),
          name: "刘 东",
          position: "创始人、董事长",
          info: "互联网金融服务行业接触代表，坚信科技改变金融，并在一线金融服务行业深耕十余年，受邀参与过多次地方及央视重量级活动。",
        },
        {
          url: require("@/images/pic__photo_02@2x.png"),
          name: "彭德伟",
          position: "联合创始人、总经理",
          info: "<div style='font-weight: bold;'>西南财经大学 金融管理</div>历任摩尔龙集团副总裁及总监，一直从事研究不良资产司法处置模式。凭借多年经验，2020年成立了整合金融、司法、律所的修业科技公司，力争打造国内一流的“一站式金融借贷纠纷服务平台”。",
        },
        {
          url: require("@/images/pic__photo_03@2x.png"),
          name: "曹 鹤",
          position: "联合创始人、副总经理",
          info: "<div style='font-weight: bold;'>成都理工大学 经济学</div>历任利信大区总，鸿特大区总，澳美海外顾问。对个人信用贷款产品以及贷后有着多年的从业管理经验，专注于为金融机构不良资产的处理提供解决方案。",
        },
        {
          url: require("@/images/pic__photo_04@2x.png"),
          name: "文裕光",
          position: "技术总监",
          info: "<div style='font-weight: bold;'>内江师范大学 通信工程</div>在政法体系信息系统平台耕耘多年，拥有丰富的司法系统平台设计、研发、实施、维护经验。带领超100人技术团队，全面负责平台研发工作。",
        },
        {
          url: require("@/images/pic__photo_06@2x.png"),
          name: "吴 天",
          position: "商务总监",
          info: "<div style='font-weight: bold;'>西南政法大学 法学专业</div> 曾任成都交通投资集团收费站稽查，成都市公安局某特警队。历任摩尔龙集团多个直营公司总经理，擅长分析产品运营市场法规及相关规定，对产品在市场的投放有着精准把控。",
        },
        {
          url: require("@/images/pic__photo_07@2x.png"),
          name: "赵建平",
          position: "四川全盈律师事务所律师 首席法务官",
          info: "<div style='font-weight: bold;'>西南政法大学 法学专业</div> 先后进入金融机构、检察机关工作，2013年辞去公职从事律师工作。从业已来，办理各类民事案件数百件，并担任四川多家公司法律顾问。",
        },
      ],
      reasons: [
        {
          url: require("@/images/reason_01@2x.png"),
          title: "提升回款率",
          info: "修复失联当事人，可批量完成对其微信、支付宝、银行存款查控，快速通过案件办理测评。",
        },
        {
          url: require("@/images/reason_02@2x.png"),
          title: "新模式解决当前金融痛点",
          info: "逾期客户分布范围广，催收成本高、内委外风险大，逾期金额低、催收难度大，无可执行资产、逾期量大。",
        },
        {
          url: require("@/images/reason_03@2x.png"),
          title: "批量高效",
          info: "可批量处理资产信息，申请立案，执行，申请保全等业务节点任务。",
        },
        {
          url: require("@/images/reason_04@2x.png"),
          title: "全流程办理",
          info: "无缝衔接法院现有系统，信息交互0等待，真正实现一站式全流程服务。",
        },
        {
          url: require("@/images/reason_05@2x.png"),
          title: "与现有法院系统契合度高",
          info: "采用最高法信息化建设标准，已得到部分法院认可与支持。",
        },
        {
          url: require("@/images/reason_06@2x.png"),
          title: "专业技术团队赋能",
          info: "超100人技术团队支撑。",
        },
      ],
    };
  },
  components: { particles },
  created() {
    /**解决2倍稿问题 ,该方法不能直接用px要转化为rem*/
    !(function (doc, win) {
      var timer,
        docEle = doc.documentElement,
        evt = "onorientationchange" in window ? "orientationchange" : "resize",
        setFontSize = function () {
          var width = docEle.getBoundingClientRect().width;
          width && (docEle.style.fontSize = 20 * (width / 850) + "px");
        };
      win.addEventListener(
        evt,
        function () {
          clearTimeout(timer);
          timer = setTimeout(setFontSize, 1000);
        },
        false
      );
      setFontSize();
    })(document, window);
  },
  methods: {
    changeMenuIndexEvent(index, el) {
      // if (el == "login") return;
      // this.menuIndex = index;
      if (el == "home") {
        document.scrollingElement.scrollTop = 0;
        this.show = false;
        return;
      }
      try {
        let top = document.getElementById(el).offsetTop;
        document.scrollingElement.scrollTop = top;
        this.show = false;
      } catch (error) {
        console.log("error", error);
      }
    },
    onChange(index) {
      this.current = index;
    },
    reasonCurrentChange(index) {
      this.reasonCurrent = index;
    },
  },
};
</script>
<style src="../assets/css/wap.css" scoped></style>
