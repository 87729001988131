<template>
  <div>
    <div :class="isFlex ? 'a_nav' : 'nav'" id="home">
      <div class="g-container g-nav">
        <a class="logo" href="https://www.xiuyekeji.com/">
          <img
            :src="
              !isFlex
                ? require('@/images/logo_xiuye_hf@2x_change.png')
                : require('@/images/logo_xiuye_hf@2x.png')
            "
          />
        </a>
        <div class="index-top-nav-m">
          <div
            v-for="(item, index) in menu"
            :key="index"
            @click="changeMenuIndexEvent(index, item.id)"
            @mouseenter="enterIndex = index"
            @mouseleave="enterIndex = menuIndex"
          >
            <div class="index-top-nav-tag" v-if="item.title !== '登 录'">
              <div
                :class="isFlex ? 'a_title' : 'title'"
                :style="{
                  color:
                    menuIndex == index || enterIndex == index
                      ? isFlex
                        ? '#FFC221'
                        : '#0071dc'
                      : '',
                }"
              >
                {{ item.title }}
              </div>
              <div
                :class="
                  index == menuIndex || enterIndex == index
                    ? isFlex
                      ? 'a_line'
                      : 'line'
                    : ''
                "
              ></div>
            </div>
            <a
              id="login"
              :class="isFlex ? 'a_login' : 'login'"
              v-else
              href="https://loan.xiuyekeji.com"
              target="_blank"
              >{{ item.title }}</a
            >
          </div>
        </div>
      </div>
    </div>
    <div class="bannner" id="banner">
      <div class="mantle">
        <div class="mantle_block"></div>
        <video v-if="returnUrl('xiuyekejibanner')" id="vd" autoplay muted loop controlslist="nodownload">
          <!-- <source
            src="https://prod-storage.moerlong.com/oss1457610892010061824.mp4"
            type="video/mp4"
          /> -->
          <source
            :src="returnUrl('xiuyekejibanner')"
            type="video/mp4"
          />
        </video>
      </div>
      <div class="bannner_title">
        <img src="@/images/banner_wa@2x.png" />
      </div>
    </div>

    <div class="advertisement" id="program">
      <div class="g-container">
        <div class="advertisement_title">
          <div>核心项目</div>
          <!-- <img src="@/images/title@2x.png" /> -->
        </div>
        <div class="advertisement_box">
          <div
            class="advertisement_box_list"
            v-for="(item, index) in advertisements"
            :key="item.title"
          >
            <img :src="item.url" />
            <div class="advertisement_box_list_info">
              <div>{{ item.title }}</div>
              <div>{{ item.info }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="environment" id="flow">
      <div class="environment_box">
        <div class="environment_title">
          <div><span>流程全自动化</span>，提供公平透明的金融环境</div>
          <img src="@/images/title@2x.png" />
        </div>
        <div class="environment_box_list">
          <div
            class="environment_box_list_tag"
            v-for="(item, index) in environments"
            :key="item.title"
          >
            <div class="tagbox">
              <img :src="item.url" />
              <div>{{ item.title }}</div>
              <div
                v-if="index == 3"
                style="font-size: 12px; margin-top: 13px; font-weight: normal"
              >
                （不回款线上开庭、执行）
              </div>
            </div>
            <img
              class="symbol"
              src="@/images/jt@2x.png"
              v-if="index + 1 != environments.length"
            />
          </div>
        </div>
      </div>
    </div> -->
    <div class="mediate g-container" id="mediate">
      <div class="mediate_title">“商调+赋强公证”，速解金融借贷纠纷强保履约率</div>
      <div class="mediate_box">
        <div class="mediate_box_left">商事调解+线上赋强公证</div>
        <div class="mediate_box_right">
          <div style="margin-bottom: 40px; white-space: nowrap;">通过调解的方式化解纠纷，降低委外合规风险，减少进入诉讼程序案件量</div>
          <div class="item">
            <div class="circle"></div>
            <div>选择调解机构并提交调解申请</div>
          </div>
          <div class="line"></div>
          <div class="item">
            <div class="circle"></div>
            <div>组织商事调解，协商沟通</div>
          </div>
          <div class="line"></div>
          <div class="item">
            <div class="circle"></div>
            <div>批量线上申请赋强公证</div>
          </div>
          <div class="line"></div>
          <div class="item">
            <div class="circle"></div>
            <div>确认生效</div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="about g-container" id="about">
      <div class="about_box">
        <div>关于我们</div>
        <div>一站式金融借贷纠纷服务平台</div>
        <div>
          主要针对金融痛点，利用互联网加密、光闸数据交换等技术，使数据实现安全交换共享从而达到人民法院的认可和支持，实现法院与系统平台的数据标准统一，为金融借贷纠纷提供的一站式、高效批量化处理的在线诉讼技术服务。为金融机构（律师事务所等）提供小金额借款合同纠纷案件纯线上批量申请立案、批量保全、调解、批量申请执行等10多项诉讼技术服务功能，将诉讼全流程服务从线下搬到线上“一键式处理”，案件材料处理采用全电子档案的方式，同时响应了人民法院全流程无纸化办案新模式，提高了企业对借贷纠纷案件的诉讼效率和诉讼数量，案件全流程自动化跟踪处理。
        </div>
        <div class="about_box_block">
          <div class="about_box_block_box" style="margin-right: 128px">
            <img src="@/images/icon_about_sjgx@2x.png" />
            <div>数据安全交换共享</div>
          </div>
          <div class="about_box_block_box">
            <img src="@/images/icon_about_wzbg@2x.png" />
            <div>无纸化办案新模式</div>
          </div>
        </div>
      </div>
      <div class="about_album">
        <div class="about_album_tab">
          <img src="@/images/about_small_bj@2x.png" /><span
            >解决贷后痛点，完成失联修复<br />
            树立诚信意识，构建和谐社会</span
          >
        </div>
      </div>
    </div> -->
    <div class="about g-container" id="about">
      <div class="about_title">关于我们</div>
      <div class="about_content">
        <div class="about_content_left">
          <div>四川雅安修业科技服务有限公司，2021年成立于四川雅安市。修业科技致力于通过科技能力和商事调解组织防范和化解金融借贷风险，构建一站式全方位、全流程金融借贷纠纷服务平台。通过公司“e站调解管理系统”赋能全国范围内调解组织，提升线上调解能力，实现无纸化高效办公能力。</div>
          <div style="margin-top: 20px;">公司秉承“公正、专业、高效、便捷”的服务理念，通过不断优化服务流程、提升服务质量，为金融机构主体提供更加优质、高效的纠纷解决途径。</div>
        </div>
        <div class="about_content_right">
          <div class="blueBox"></div>
          <img :src="returnUrl('xiuyekeji')" />
        </div>
      </div>
      
    </div>

    <div class="missionVision g-container">
      <div class="item">
        <img src="../images/mission.png" style="margin-right: 16px;">
        <div>
          <div style="font-size: 20px; color: #040F1B">使命</div>
          <div style="font-size: 16px; color: #666; margin-top: 10px;">强化商事调解，筑牢金融借贷风险防线</div>
        </div>
      </div>
      <div class="item">
        <img src="../images/vision.png" style="margin-right: 16px;">
        <div>
          <div style="font-size: 20px; color: #040F1B">愿景</div>
          <div style="font-size: 16px; color: #666; margin-top: 10px;">减矛盾，筑诚信，共筑和谐社会</div>
        </div>
      </div>
    </div>

    <div class="advantage g-container" id="advantage">
      <div class="g-container">
        <div class="title">六大核心优势</div>
        <div class="subtitle">为金融借贷纠纷提供一站式高效率批量化的技术服务</div>
        <img :src="returnUrl('advantage')" />
      </div>
    </div>
    <!-- <div class="process" id="process">
      <div class="g-container process_box">
        <div class="process_title">
          <div>创始人介绍</div>
          <div>司法，科技，服务</div>
          <img src="@/images/title@2x.png" />
        </div>
        <div class="mySwiper">
          <div class="mySwiperBoxleftBtutton" @click="slideNext">
            <img
              v-if="isLeftBtutton"
              src="@/images/l_default@2x.png"
              @mouseenter="isLeftBtutton = false"
            />
            <img
              v-else
              src="@/images/l_highlight@2x.png"
              @mouseleave="isLeftBtutton = true"
            />
          </div>
          <swiper
            style="width: 1236px"
            class="mySwiperBox"
            ref="mySwiper"
            :options="swiperOptions"
          >
            <swiper-slide v-for="(datas, index) in processDatas" :key="index">
              <div class="process_list">
                <div class="spot">
                  <img src="@/images/spot.png" />
                </div>
                <div class="process_user">
                  <img class="process_user_img" :src="datas.url" />
                  <div class="process_user_name">
                    <div>{{ datas.name }}</div>
                    <div>{{ datas.position }}</div>
                  </div>
                </div>
                <div class="process_user_info" v-html="datas.info"></div>
              </div>
            </swiper-slide>
          </swiper>
          <div class="mySwiperBoxleftBtutton" @click="slidePrev">
            <img
              v-if="isRightBtutton"
              src="@/images/r_default@2x.png"
              @mouseenter="isRightBtutton = false"
            />
            <img
              v-else
              src="@/images/r_highlight@2x.png"
              @mouseleave="isRightBtutton = true"
            />
          </div>
        </div>
      </div>
      <particles />
    </div> -->

    <!-- <div class="reason" id="reason">
      <div class="reason_title">
        <div>选择我们的六大理由</div>
        <div>为金融借贷纠纷提供一站式高效率批量化的技术服务</div>
        <img src="@/images/title@2x.png" />
      </div>
      <swiper
        :options="swiperOptionsReason"
        style="height: 57vh"
        class="reason_swiper"
      >
        <swiper-slide v-for="(item, index) in reasons" :key="index">
          <div class="reason_swiper_slide">
            <div class="reason_swiper_slide_img">
              <img :src="item.url" />
            </div>

            <div class="reason_swiper_slide_info">
              <div>{{ item.title }}</div>
              <div>{{ item.info }}</div>
            </div>
          </div>
        </swiper-slide>

        <div
          class="swiper-pagination swiper-pagination_box"
          slot="pagination"
        ></div>
      </swiper>
    </div> -->

    <!-- <div class="contact" id="contact">
      <div class="contact_title">
        <div>招聘信息</div>
        <div>给梦想，一个机会 ，让世界，都看到你</div>
        <img src="@/images/title@2x.png" />
      </div>
      <div class="contact_info">
        <div class="contact_info_name">岗位：调解员（20-40人）</div>
        <div class="contact_info_box">
          <div class="contact_info_list">
            <div class="position">招聘内容</div>
            <div class="info">
              1.工作地点：四川省雅安市<br />
              2.工作时间：朝九晚六、周末双休、享受国家法定节假日<br />
              3.薪资待遇：4-8k<br />
            </div>
          </div>

          <div class="contact_info_list">
            <div class="position">岗位职责</div>
            <div class="info">
              1.根据公司要求负责逾期客户的电话调解<br />
              2.协助客户还款、解除财产冻结、撤诉等相关工作<br />
              3.做好数据统计与资料储存<br />
            </div>
          </div>
          <div class="contact_info_list">
            <div class="position">简历投递</div>
            <div class="info">
              1.姓名：高冉<br />
              2.电话：13688034340<br />
              3.邮箱：gaoran@xiuyekeji.com<br />
            </div>
          </div>
        </div>
      </div>
      <img class="bg_img" src="@/images/zp_bj@2x.png" />
    </div> -->
    <footer class="footer">
      <div class="g-container footer_container">
        <div class="contact_information">
          <div
            class="contact_information_tag"
            v-for="(item, index) in footerContact"
            :key="item.title"
          >
            <div :title="item.title.replace(/<br>/g, '')">{{ item.title }}</div>
            <div
              v-html="item.info"
              :title="item.info.replace(/<br>/g, '')"
            ></div>
          </div>
        </div>
        <!-- <div class="subsidiary">
          法律支持单位：四川全盈律师事务所<br />
          政府合作支持单位：四川蜀渝云数据产业园有限公司<br />
          旗下公司：雅安市君信基业商务信息咨询有限公司、四川品资商务信息咨询服务有限公司
        </div> -->
      </div>
      <div class="footer_container_nav">
        Copyright © 2021-2024 XIUYEKEJI Corporation.All Rights Reserved. <br />
        <!-- 四川雅安修业科技服务有限公司 -->
        <a
          href="https://beian.miit.gov.cn/#/Integrated/index"
          target="_blank"
          style="color: #7e889d"
        >
          蜀ICP备2021028841号
        </a>
      </div>
    </footer>
  </div>
</template>
<script>
import particles from "@/components/particles/index.vue";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/swiper-bundle.css";
import { getImageUrlAPI } from "@/api";
export default {
  components: { particles, swiper, swiperSlide },
  metaInfo: {
    title: "修业科技",
    meta: [
      {
        // set meta
        name: "keyWords",
        content: "修业科技,修业科技官网,雅安修业科技有限公司",
      },
      {
        name: "description",
        content:
          "主要针对金融痛点，利用互联网加密、光闸数据交换等技术，使数据实现安全交换共享从而达到人民法院的认可和支持，实现法院与系统平台的数据标准统一，为金融借贷纠纷提供的一站式、高效批量化处理的在线诉讼技术服务。为金融机构（律师事务所等）提供小金额借款合同纠纷案件纯线上批量申请立案、批量保全、调解、批量申请执行等10多项诉讼技术服务功能，将诉讼全流程服务从线下搬到线上“一键式处理”，案件材料处理采用全电子档案的方式，同时响应了人民法院全流程无纸化办案新模式，提高了企业对借贷纠纷案件的诉讼效率和诉讼数量，案件全流程自动化跟踪处理。",
      },
    ],
    link:[{
      rel: 'asstes',
      href: 'https://www.xiuyekeji.com/'
    }]
  },
  name: "Home",
  data() {
    return {
      footerContact: [
        {
          title: "总经理：曹鹤",
          info: `邮箱地址：caohe@xiuyekeji.com`,
        },
        {
          title: "四川雅安修业科技服务有限公司",
          info: "四川省雅安市名山区蒙阳镇水井街88号11幢12层1206号<br>法律支持单位：四川全盈律师事务所",
        },
      ],
      menuIndex: 0,
      enterIndex: 0,
      isLeftBtutton: true,
      isRightBtutton: true,
      menu: [
        {
          title: "首 页",
          id: "home",
        },
        {
          title: "核心项目",
          id: "program",
        },
        {
          title: "核心业务",
          id: "mediate",
        },
        {
          title: "关于我们",
          id: "about",
        },
        // {
        //   title: "选择我们的理由",
        //   id: "reason",
        // },
        {
          title: "六大优势",
          id: "advantage",
        },

        {
          title: "登 录",
          id: "login",
        },
      ],
      advertisements: [
        {
          url: require("@/images/mediate.png"),
          title: "商事调解",
          info: "通过依法成立的商事调解中心，对金融借贷纠纷开展线上音视频调解",
        },
        {
          url: require("@/images/justice.png"),
          title: "线上赋强公证",
          info: "系统线上对接公证机构，对调解达成的调解文书进行赋强公证",
        },
        {
          url: require("@/images/step.png"),
          title: "批量卷宗系统",
          info: "高效批量整理，确保全面符合特定文书规范与要求",
        },
      ],
      environments: [
        {
          title: "诉讼材料批量生成",
          url: require("@/images/icon_business_01@2x.png"),
        },
        {
          title: "批量移送法院起诉",
          url: require("@/images/icon_business_02@2x.png"),
        },
        {
          title: "法院批量立案保全",
          url: require("@/images/icon_business_03@2x.png"),
        },
        {
          title: "客户调解回款",
          url: require("@/images/icon_business_04@2x.png"),
        },
        {
          title: "结案",
          url: require("@/images/icon_business_05@2x.png"),
        },
      ],
      swiperOptionsReason: {
        loop: true,
        slidesPerView: 4,
        spaceBetween: 30,
        centeredSlides: true,
        autoplay: {
          delay: 9000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true, //允许分页点击跳转
        },
      },
      swiperOptions: {
        loop: true,
        slidesPerView: 3,
        spaceBetween: 15,
        autoplay: {
          delay: 8000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
      },
      /**创始人 */
      processDatas: [
        {
          url: require("@/images/pic__photo_01@2x.png"),
          name: "刘 东",
          position: "创始人、董事长",
          info: "互联网金融服务行业接触代表，坚信科技改变金融，并在一线金融服务行业深耕十余年，受邀参与过多次地方及央视重量级活动。",
        },
        {
          url: require("@/images/pic__photo_02@2x.png"),
          name: "彭德伟",
          position: "联合创始人、总经理",
          info: "<div style='font-weight: bold;'>西南财经大学 金融管理</div>历任摩尔龙集团副总裁及总监，一直从事研究不良资产司法处置模式。凭借多年经验，2020年成立了整合金融、司法、律所的修业科技公司，力争打造国内一流的“一站式金融借贷纠纷服务平台”。",
        },
        {
          url: require("@/images/pic__photo_03@2x.png"),
          name: "曹 鹤",
          position: "联合创始人、副总经理",
          info: "<div style='font-weight: bold;'>成都理工大学 经济学</div>历任利信大区总，鸿特大区总，澳美海外顾问。对个人信用贷款产品以及贷后有着多年的从业管理经验，专注于为金融机构不良资产的处理提供解决方案。",
        },
        {
          url: require("@/images/pic__photo_04@2x.png"),
          name: "文裕光",
          position: "技术总监",
          info: "<div style='font-weight: bold;'>内江师范大学 通信工程</div>在政法体系信息系统平台耕耘多年，拥有丰富的司法系统平台设计、研发、实施、维护经验。带领超100人技术团队，全面负责平台研发工作。",
        },
        {
          url: require("@/images/pic__photo_06@2x.png"),
          name: "吴 天",
          position: "商务总监",
          info: "<div style='font-weight: bold;'>西南政法大学 法学专业</div> 曾任成都交通投资集团收费站稽查，成都市公安局某特警队。历任摩尔龙集团多个直营公司总经理，擅长分析产品运营市场法规及相关规定，对产品在市场的投放有着精准把控。",
        },
        {
          url: require("@/images/pic__photo_07@2x.png"),
          name: "赵建平",
          position: "四川全盈律师事务所律师 首席法务官",
          info: "<div style='font-weight: bold;'>西南政法大学 法学专业</div> 先后进入金融机构、检察机关工作，2013年辞去公职从事律师工作。从业已来，办理各类民事案件数百件，并担任四川多家公司法律顾问。",
        },
      ],
      /**选择我们的六大理由 */
      reasons: [
        {
          url: require("@/images/reason_01@2x.png"),
          title: "提升回款率",
          info: "修复失联当事人，可批量完成对其微信、支付宝、银行存款查控，快速通过案件办理测评。",
        },
        {
          url: require("@/images/reason_02@2x.png"),
          title: "新模式解决当前金融痛点",
          info: "逾期客户分布范围广，催收成本高、内委外风险大，逾期金额低、催收难度大，无可执行资产、逾期量大。",
        },
        {
          url: require("@/images/reason_03@2x.png"),
          title: "批量高效",
          info: "可批量处理资产信息，申请立案，执行，申请保全等业务节点任务。",
        },
        {
          url: require("@/images/reason_04@2x.png"),
          title: "全流程办理",
          info: "无缝衔接法院现有系统，信息交互0等待，真正实现一站式全流程服务。",
        },
        {
          url: require("@/images/reason_05@2x.png"),
          title: "与现有法院系统契合度高",
          info: "采用最高法信息化建设标准，已得到部分法院认可与支持。",
        },
        {
          url: require("@/images/reason_06@2x.png"),
          title: "专业技术团队赋能",
          info: "超100人技术团队支撑。",
        },
      ],
      isFlex: true,
      fileList: []
    };
  },

  mounted() {
    getImageUrlAPI({
      applicableSystem: '04'
    }).then((res) => {
      this.fileList = res.data
    })
    document.onkeydown =
      document.onkeyup =
      document.onkeypress =
        function (event) {
          let e = event || window.event || arguments.callee.caller.arguments[0];
          if (e && e.keyCode == 123) {
            e.returnValue = false;
            return false;
          }
        };
    document.oncontextmenu = function (event) {
      if (window.event) {
        event = window.event;
      }
      try {
        var the = event.srcElement;
        if (
          !(
            (the.tagName == "INPUT" && the.type.toLowerCase() == "text") ||
            the.tagName == "TEXTAREA"
          )
        ) {
          return false;
        }
        return true;
      } catch (e) {
        return false;
      }
    };
    window.onscroll = (el) => {
      var t = document.documentElement.scrollTop || document.body.scrollTop;
      this.isFlex = t <= document.getElementById("banner").offsetHeight;
      const DOM = (id) => {
        return {
          t,
          offsetHeight: document.getElementById(id).offsetHeight,
          viewportHeight: document.getElementById(id).offsetTop,
        };
      };
      let DOMArr = [
        DOM("home"),
        DOM("flow"),
        DOM("about"),
        DOM("process"),
        DOM("contact"),
      ];
      if (this.isFlex) {
        (this.menuIndex = 0), (this.enterIndex = 0);
        return;
      }
      for (let i = DOMArr.length - 1; i > 0; i--) {
        if (t >= DOMArr[i].viewportHeight - 300) {
          (this.menuIndex = i), (this.enterIndex = i);
          break;
        }
      }
    };
  },

  methods: {
    /**切换和处理锚定功能 */
    changeMenuIndexEvent(index, el) {
      if (el == "login") return;
      this.menuIndex = index;
      if (el == "home") {
        document.documentElement.scrollTop = 0;
        return;
      }
      let top = document.getElementById(el).offsetTop;
      document.documentElement.scrollTop = top - 100;
    },

    slideNext() {
      this.$refs.mySwiper.swiper.slidePrev();
    },
    slidePrev() {
      this.$refs.mySwiper.swiper.slideNext();
    },
    returnUrl(fileName) {
      try {
        return this.fileList.find((item) => item.fileName == fileName).fileUrl;
      }catch{
        return ''
      }
    }
  },
};
</script>
<style src="../assets/css/pc.css" scoped>
</style>
