import CryptoJS from "crypto-js";

/**encrypt加密，decrypt解密 */
export function xinyuntiaoaes() {
  const key = CryptoJS.enc.Hex.parse("94e874a7d137737ab11e1531d8ab3f6d");
  const iv = CryptoJS.enc.Hex.parse("94e874a7d137737ab11e1531d8ab3f6d");
  /**加密 */
  function encrypt(data) {
    const enc = CryptoJS.AES.encrypt(data, key, {
      iv,
    });
    return enc.toString();
  }
  /**解密 */
  function decrypt(data) {
    const dec = CryptoJS.AES.decrypt(data, key, {
      iv,
    });
    return CryptoJS.enc.Utf8.stringify(dec);
  }
  return {
    encrypt,
    decrypt,
  };
}

/**加密post */
export function setPostData(data = {}, config) {
  // if (process.env.NODE_ENV == "development" || process.env.NODE_ENV == "staging") return data;
  data["url"] = config.url;
  if (process.env.NODE_ENV == "development" || process.env.NODE_ENV == "staging") {
    console.table(data);
  }
  return {
    arg0: data ? xinyuntiaoaes().encrypt(JSON.stringify(data)) : "",
  };
}
/**加密get */
export function setGetParams(url = "") {
  // if (process.env.NODE_ENV == "development" || process.env.NODE_ENV == "staging") return url;
  let params = url.substring(url.indexOf("?") + 1, url.length);
  if (url.indexOf("?") !== -1) {
    url = url.substring(0, url.indexOf("?"));
  }
  let strs = params.split("&");
  let obj = {};
  for (let i in strs) {
    obj[strs[i].split("=")[0]] =
      decodeURIComponent(strs[i].split("=")[1]) || "";
  }
  obj["url"] = url || "";

  //判断是开发环境和生成环境
  if (process.env.NODE_ENV == "development") {
    console.table(obj);
  }
  return `${url}?arg0=${encodeURIComponent(
    encodeURIComponent(xinyuntiaoaes().encrypt(params))
  )}`;
}

/**解密 */
export function getDataDecrypt(res = null) {
  let { config, data } = res;
  // if (process.env.NODE_ENV == "development"  || process.env.NODE_ENV == "staging") return data;
  if (data == null) return data;
  if(config == null) return config
  if (
    config.url.includes("/code") ||
    config["responseType"] == "blob" ||
    config["responseType"] == "arraybuffer"
  ) {
    return data;
  }
  let req = null;
  try {
    req = JSON.parse(xinyuntiaoaes().decrypt(data));
  } catch (error) {
    if (error) {
      console.log(config)
      // this.$message.error(`${config.url}，当接口返回参数未加密！`);
      return;
    }
  }
  return req;
}
