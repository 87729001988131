import axios from "axios";
import { setPostData, setGetParams, getDataDecrypt } from "./crypto.js";
import { Notification, Message } from "element-ui";

// 创建axios实例
const baseURL = 'https://loan.xiuyekeji.com/api'
// const baseURL = "http://192.168.1.211:8080";
// const baseURL = "http://192.168.1.114:8888";
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL,
  // 超时
  timeout: 300000,
});

// request拦截器
service.interceptors.request.use(
  (config) => {
    // 是否需要设置 token
    const isToken = (config.headers || {}).isToken === false;
    // 是否需要防止数据重复提交
    const isRepeatSubmit = (config.headers || {}).repeatSubmit === false;

    // get请求映射params参数
    if (config.method === "get" && config.params) {
      let url = config.url + "?" + tansParams(config.params);
      url = url.slice(0, -1);
      config.params = {};
      config.url = setGetParams(url);
    }
    if (config.dz) {
      delete config.dz;
      // config.headers["dz"] = config.dz
    }
    // config.data.tjzxid = TJZZID
    if (
      !isRepeatSubmit &&
      (config.method === "post" ||
        config.method === "put" ||
        config.method === "delete")
    ) {
      const requestObj = {
        url: config.url,
        data:
          typeof config.data === "object"
            ? JSON.stringify(config.data)
            : config.data,
        time: new Date().getTime(),
      };
      const requestSize = Object.keys(JSON.stringify(requestObj)).length; // 请求数据大小
      const limitSize = 5 * 1024 * 1024; // 限制存放数据5M
      if (requestSize >= limitSize) {
        console.warn(
          `[${config.url}]: ` +
            "请求数据大小超出允许的5M限制，无法进行防重复提交验证。"
        );
        return config;
      }
    }
    if (
      !(config.data instanceof FormData) &&
      (config.method == "post" ||
        config.method == "put" ||
        config.method == "delete")
    ) {
      config["data"] = setPostData(config.data, config);
    }
    if (config.type == "file" && process.env.NODE_ENV != "development") {
      config.data["arg0"] = encodeURIComponent(
        encodeURIComponent(config.data.arg0)
      );
    }

    return config;
  },
  (error) => {
    console.log(error);
    Promise.reject(error);
  }
);

// 响应拦截器
service.interceptors.response.use(
  (res) => {
    res["data"] = getDataDecrypt({
      data: res.data,
      config: res.config,
    });
    const { msg } = res.data;
    // 未设置状态码则默认成功状态
    const code = res.data.code || 200;
    // 二进制数据则直接返回
    if (
      res.request.responseType === "blob" ||
      res.request.responseType === "arraybuffer" ||
      res.request.responseType === "delete"
    ) {
      return res.data;
    } else if (code === 500) {
      Message({
        message: msg,
        // message: '服务器错误',
        type: "error",
        //为了防止影响其他位置，特意检测是否为指定接口
        dangerouslyUseHTMLString: res.config.url.includes("importData")
          ? true
          : false,
      });
      return Promise.reject(new Error(msg));
    } else if (code === 601) {
      Message({ message: msg, type: "warning" });
      return Promise.reject("error");
    } else if (code !== 200) {
      Notification.error({ title: msg });
      return Promise.reject("error");
    } else {
      process.env.NODE_ENV == "development"
        ? console.info({
            url: res.config.url,
            ...res.data,
          })
        : "";
      return res.data;
    }
  },
  (error) => {
    console.log("err" + error);
    let { message } = error;
    if (message == "Network Error") {
      message = "后端接口连接异常";
    } else if (message.includes("timeout")) {
      message = "系统接口请求超时";
    } else if (message.includes("Request failed with status code")) {
      message = "系统接口" + message.substr(message.length - 3) + "异常";
    }
    console.log("响应异常", error);

    Message({ message: message, type: "error", duration: 5 * 1000 });
    return Promise.reject(error);
  }
);
export default service;