import router from '../../../src/router/index.js'

function returnUserAgent() {
    var sUserAgent = navigator.userAgent.toLowerCase();
    var bIsIpad = sUserAgent.match(/ipad/i) == "ipad";
    var bIsIOS = !!sUserAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
    var bIsIphoneOs = sUserAgent.match(/iphone os/i) == "iphone os";
    var bIsMidp = sUserAgent.match(/midp/i) == "midp";
    var bIsUc7 = sUserAgent.match(/rv:1.2.3.4/i) == "rv:1.2.3.4";
    var bIsUc = sUserAgent.match(/ucweb/i) == "ucweb";
    var bIsAndroid = sUserAgent.match(/android/i) == "android";
    var bIsCE = sUserAgent.match(/windows ce/i) == "windows ce";
    var bIsWM = sUserAgent.match(/windows mobile/i) == "windows mobile";
    var bIsWechat = sUserAgent.match(/MicroMessenger/i) == "micromessenger";
    return {
        bIsIpad,
        bIsIphoneOs,
        bIsMidp,
        bIsUc7,
        bIsUc,
        bIsAndroid,
        bIsIOS,
        bIsCE,
        bIsWM,
        bIsWechat,
    };
}

/**判断是否是移动端打开 */
const browserRedirect = () => {
    var userAgent = returnUserAgent();
    if (
        userAgent.bIsIpad ||
        userAgent.bIsIphoneOs ||
        userAgent.bIsMidp ||
        userAgent.bIsUc7 ||
        userAgent.bIsUc ||
        userAgent.bIsAndroid ||
        userAgent.bIsCE ||
        userAgent.bIsWM
    ) {
        router.push("/wap").catch((err) =>{} )
        return
    }
    router.push("/").catch((err) => {})
};
browserRedirect()